@import "value";
@import "mixin";
@import "iconfont";
.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.clearfix {
    @include clearfix();
}

.bor-b {
    border-bottom: 1px solid $borc;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: Microsoft Yahei;
}

html,
body {
    width: 100%;
    height: 100%;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.login-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .login-bg-shade {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0.5;
        filter: alpha(opacity=50);
    }
}

.login-box {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    right: 0;
    width: 390px;
    height: 480px;
    margin: -240px auto auto auto;
    .login-box-head {
        color: $blue;
        text-align: center;
        margin-bottom: 20px;
        .logo {
            margin-right: 5px;
            width: 35px;
            height: 35px;
            vertical-align: bottom;
        }
        b {
            font-size: 26px;
            font-weight: normal;
        }
        i {
            margin-left: 5px;
            font-size: 16px;
            font-style: normal;
        }
    }
    .login-box-body {
        width: 300px;
        //height: 335px;
        margin: auto;
        padding: 20px;
        border-radius: 5px;
        background: #fff;
        color: $fc;
        a {
            color: $blue;
            font-size: 12px;
        }
        .form-group {
            margin: 0 0 10px 0;
            .form-tit {
                height: 30px;
                line-height: 30px;
                font-size: 14px;
            }
            .form-cont {
                display: block;
                width: 100%;
                height: 30px;
                line-height: 30px;
                padding: 0 10px;
                border: 1px solid $borc;
                font-size: 14px;
                outline: none;
                input {
                    height: 28px;
                    line-height: 28px;
                    border: 0;
                    outline: none;
                }
            }
            .form-code {
                width: 85px;
                height: 30px;
                border: 1px solid $borc;
            }
            .form-refresh {
                display: block;
                width: 30px;
                height: 30px;
                text-align: center;
                i {
                    color: $fc;
                    font-size: 20px;
                    vertical-align: middle;
                }
            }
            .form-btn {
                display: block;
                width: 100%;
                height: 35px;
                line-height: 35px;
                margin-top: 30px;
                border: 1px solid #4b8caa;
                border-radius: 3px;
                background: $blue;
                color: #fff;
                font-size: 14px;
                text-align: center;
                &:hover {
                    background: $blue2;
                    text-decoration: none;
                }
            }
            &.form-tips {
                padding-bottom: 10px;
                p {
                    line-height: 18px;
                    font-size: 14px;
                }
                small {
                    color: $fc4;
                    font-size: 12px;
                }
            }
            .getTelCodeBtn {
                color: $blue;
                font-size: 12px;
                background: none;
            }
        }
        .login-tips {
            color: #fb6b5b;
            font-size: 12px;
        }
    }
    .login-box-foot {
        margin-top: 25px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        .ul-link {
            margin-bottom: 15px;
            >li {
                position: relative;
                display: inline-block;
                margin: 0 10px;
                font-size: 14px;
                &:before,
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: -11px;
                    height: 16px;
                    margin-top: -8px;
                    border-left: 1px solid #fff;
                }
                &:first-child {
                    &:before,
                    &::before {
                        display: none;
                    }
                }
                >a {
                    color: #fff;
                }
            }
        }
    }
}
