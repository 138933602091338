.c-red {
  color: #fb6b5b;
}

.c-blue {
  color: #1f92e2;
}

.c-green {
  color: #53b567;
}

.c-yellow {
  color: #ffbc1a;
}

.c-9 {
  color: #999;
}

.c-6 {
  color: #666;
}

@font-face {
  font-family: "jizhiyun";
  src: url("../fonts/jzyiconfont.eot?");
  src: url("../fonts/jzyiconfont.eot?#iefix") format("embedded-opentype"), url("../fonts/jzyiconfont.woff?") format("woff"), url("../fonts/jzyiconfont.ttf?") format("truetype"), url("../fonts/jzyiconfont.svg?#iconfont") format("svg");
}

[class^="icon-jzy-"]:before,
[class*=" icon-jzy-"]:before {
  content: "";
  font-family: "jizhiyun";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jzy-shuaxin:before {
  content: "\e665";
}

.icon-jzy-download:before {
  content: "\e6c8";
}

.icon-jzy-tuding1:before {
  content: "\e643";
}

.icon-jzy-guanbi:before {
  content: "\e611";
}

.icon-jzy-tianjia:before {
  content: "\e77e";
}

.icon-jzy-user:before {
  content: "\e62d";
}

.icon-jzy-users:before {
  content: "\e6a3";
}

.icon-jzy-tongji:before {
  content: "\e61f";
}

.icon-jzy-xiaoxi:before {
  content: "\e6b6";
}

.icon-jzy-user2:before {
  content: "\e686";
}

.icon-jzy-tuichu:before {
  content: "\e7de";
}

.icon-jzy-lingdang:before {
  content: "\e64b";
}

.icon-jzy-zhankai:before {
  content: "\e62c";
}

.icon-jzy-shouqi:before {
  content: "\e63e";
}

.icon-jzy-shenhe:before {
  content: "\e65f";
}

.icon-jzy-xitong:before {
  content: "\e6fa";
}

.icon-jzy-gaikuang:before {
  content: "\e952";
}

.icon-jzy-gujian:before {
  content: "\e68d";
}

.icon-jzy-xiangmu:before {
  content: "\e600";
}

.icon-jzy-app:before {
  content: "\e623";
}

.icon-jzy-tuding2:before {
  content: "\e953";
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  *zoom: 1;
}

.clearfix:after {
  clear: both;
}

.bor-b {
  border-bottom: 1px solid #e3e3e3;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Microsoft Yahei;
}

html,
body {
  width: 100%;
  height: 100%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.login-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-bg .login-bg-shade {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.login-box {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  right: 0;
  width: 390px;
  height: 480px;
  margin: -240px auto auto auto;
}

.login-box .login-box-head {
  color: #1f92e2;
  text-align: center;
  margin-bottom: 20px;
}

.login-box .login-box-head .logo {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  vertical-align: bottom;
}

.login-box .login-box-head b {
  font-size: 26px;
  font-weight: normal;
}

.login-box .login-box-head i {
  margin-left: 5px;
  font-size: 16px;
  font-style: normal;
}

.login-box .login-box-body {
  width: 300px;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  color: #666;
}

.login-box .login-box-body a {
  color: #1f92e2;
  font-size: 12px;
}

.login-box .login-box-body .form-group {
  margin: 0 0 10px 0;
}

.login-box .login-box-body .form-group .form-tit {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.login-box .login-box-body .form-group .form-cont {
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border: 1px solid #e3e3e3;
  font-size: 14px;
  outline: none;
}

.login-box .login-box-body .form-group .form-cont input {
  height: 28px;
  line-height: 28px;
  border: 0;
  outline: none;
}

.login-box .login-box-body .form-group .form-code {
  width: 85px;
  height: 30px;
  border: 1px solid #e3e3e3;
}

.login-box .login-box-body .form-group .form-refresh {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
}

.login-box .login-box-body .form-group .form-refresh i {
  color: #666;
  font-size: 20px;
  vertical-align: middle;
}

.login-box .login-box-body .form-group .form-btn {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  margin-top: 30px;
  border: 1px solid #4b8caa;
  border-radius: 3px;
  background: #1f92e2;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.login-box .login-box-body .form-group .form-btn:hover {
  background: #1e84cf;
  text-decoration: none;
}

.login-box .login-box-body .form-group.form-tips {
  padding-bottom: 10px;
}

.login-box .login-box-body .form-group.form-tips p {
  line-height: 18px;
  font-size: 14px;
}

.login-box .login-box-body .form-group.form-tips small {
  color: #999;
  font-size: 12px;
}

.login-box .login-box-body .form-group .getTelCodeBtn {
  color: #1f92e2;
  font-size: 12px;
  background: none;
}

.login-box .login-box-body .login-tips {
  color: #fb6b5b;
  font-size: 12px;
}

.login-box .login-box-foot {
  margin-top: 25px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.login-box .login-box-foot .ul-link {
  margin-bottom: 15px;
}

.login-box .login-box-foot .ul-link > li {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  font-size: 14px;
}

.login-box .login-box-foot .ul-link > li:before, .login-box .login-box-foot .ul-link > li::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -11px;
  height: 16px;
  margin-top: -8px;
  border-left: 1px solid #fff;
}

.login-box .login-box-foot .ul-link > li:first-child:before, .login-box .login-box-foot .ul-link > li:first-child::before {
  display: none;
}

.login-box .login-box-foot .ul-link > li > a {
  color: #fff;
}
